<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Button from "./Button.svelte";
    import ButtonGroup from "./ButtonGroup.svelte";
    import { v4 as uuidv4 } from "uuid";
    import type { Atopic } from "../types/Atopic";
    import { uploadImageReaction } from "../stores/reaction-store";
    import Dialog from "./Dialog.svelte";

    const dispatch = createEventDispatcher();

    export let file: File | undefined;
    export let atopic: Atopic;
    let idempotencyToken: string = uuidv4();
    let uploadRequest: Promise<{ uuid: string }> | undefined;
    let dialogOpen = false;
    let src: string | undefined = undefined;
    let uploadError = false;

    $: if (file) {
        dialogOpen = true;
        src = URL.createObjectURL(file);
        uploadRequest = undefined;
        idempotencyToken = uuidv4();
    }

    function handleDialogClose() {
        dialogOpen = false;
        dispatch("close");
    }

    async function upload() {
        if (!file) throw "no file selected";
        try {
            uploadError = false;
            const result = await uploadImageReaction({ atopic, file, idempotencyToken });
            dispatch("success", result);
            handleDialogClose();
            return result;
        } catch (e: unknown) {
            uploadError = true;
            throw e;
        }
    }

    async function handleSubmit() {
        if (!file) return;
        uploadRequest = upload();
    }
</script>

<Dialog
    open={dialogOpen}
    error={uploadError ? "Leider ist etwas schief gelaufen" : undefined}
    reduceWidth
>
    <img {src} alt="test" />
    <svelte:fragment slot="controls">
        <ButtonGroup center>
            {#if !uploadRequest}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                <Button on:click={handleSubmit}>Hochladen</Button>
            {/if}
            {#await uploadRequest}
                lädt...
            {:catch}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                <Button on:click={handleSubmit}>Nochmal</Button>
            {/await}
        </ButtonGroup>
    </svelte:fragment>
</Dialog>

<style>
    img {
        max-width: 100%;
        min-width: 50vw;
        min-height: 50vw;
        max-height: 60vh;
        display: block;
        object-fit: cover;
        padding: 0;
        background: white;
    }
</style>
