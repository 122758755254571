<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Dialog from "./Dialog.svelte";
    import { uploadTextReaction } from "../stores/reaction-store";
    import type { Atopic } from "../types/Atopic";
    import Button from "./Button.svelte";
    import ButtonGroup from "./ButtonGroup.svelte";
    import TextArea from "./TextArea.svelte";
    import { v4 as uuidv4 } from "uuid";

    const dispatch = createEventDispatcher();

    export let atopic: Atopic;
    let idempotencyToken: string = uuidv4();
    let uploadRequest: Promise<{ uuid: string }>;
    let dialogOpen = true;
    let text: string = "";
    let uploadError = false;

    $: text, (idempotencyToken = uuidv4());

    function handleDialogClose() {
        dialogOpen = false;
        dispatch("close");
    }

    async function upload() {
        try {
            uploadError = false;
            const result = await uploadTextReaction({ atopic, text, idempotencyToken });
            dispatch("success", result);
            handleDialogClose();
            return result;
        } catch (e: unknown) {
            uploadError = true;
            throw e;
        }
    }

    function handleSubmit() {
        uploadRequest = upload();
    }
</script>

<Dialog open={dialogOpen} top error={uploadError ? "Leider ist etwas schief gelaufen" : undefined}>
    <TextArea maxRows={15} minRows={4} bind:value={text} />
    <svelte:fragment slot="controls">
        <ButtonGroup center>
            {#if !uploadRequest}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                <Button on:click={handleSubmit}>Hochladen</Button>
            {/if}
            {#await uploadRequest}
                lädt...
            {:catch}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                <Button on:click={handleSubmit}>Nochmal</Button>
            {/await}
        </ButtonGroup>
    </svelte:fragment>
</Dialog>

<style>
</style>
