<script lang="ts">
    import { onMount } from "svelte";
    import { location } from "svelte-spa-router";
    import HandContainer from "../lib/HandContainer.svelte";
    import Spinner from "../lib/Spinner.svelte";
    import { createChannelRemote } from "../util/inter-process-communication";
    let promise: Promise<void>;

    const channel = createChannelRemote("sharelink");

    onMount(() => {
        promise = channel.send($location);
    });
</script>

<HandContainer foregroundColor="#0000ff" backgroundColor="#9baac8">
    {#await promise}
        <Spinner />
    {:then}
        <div>
            <p class="center">Shared Atopics incoming!</p>
            <p>Mit dir wurde eine Liste von Atopics geteilt.</p>
            <p>
                Öffne jetzt die Atopics-App um sie dir anzusehen.<br />
            </p>
            <a href="#/">Wie installiere ich die Atopics-App?</a>
        </div>
    {/await}
</HandContainer>

<style>
    p {
        margin-bottom: 1rem;
    }

    p.center {
        text-align: center;
    }

    a {
        text-decoration: underline;
    }
</style>
