<script lang="ts">
    import { push } from "svelte-spa-router";
    import VisibilityChange from "svelte-visibility-change";
    import { createChannelRemote } from "../util/inter-process-communication";

    const channel = createChannelRemote<string>("sharelink");

    async function handleVisible() {
        try {
            const link = await channel.receive(30);
            console.log("check link", link);
            if (!!link && link.startsWith("/")) {
                push(link);
            }
        } catch (e) {
            console.log(e);
        }
    }
</script>

<VisibilityChange on:visible={handleVisible} />
