<script lang="ts">
    import { fly } from "svelte/transition";
    import flip from "../transitions/flip";
    import Hand from "./Hand.svelte";

    export let backgroundColor: string = "#ffacff";
    export let foregroundColor: string = "#500f00";
</script>

<div class="container" style={`--fg-color: ${foregroundColor}; --bg-color: ${backgroundColor}`}>
    <div
        in:flip|local={{ out: false, liftUp: false }}
        out:flip|local={{ out: true, liftUp: false }}
    >
        <!-- <div class="color-overlay" /> -->
        <div class="card">
            <slot />
        </div>
    </div>
</div>

<style>
    .container {
        padding-top: calc(env(safe-area-inset-top) + 4rem);
        line-height: 1.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 600;
        perspective-origin: center center;
        perspective: calc(80vw * 11);
        height: 100%;
    }

    .card {
        color: var(--fg-color);
        background: var(--bg-color);
        /* padding: 0.5rem; */
        transform: rotate(2deg);
        transform-style: preserve-3d;
        width: 90vw;
        max-width: 40rem;
        /* min-width: 50vw; */
        height: 30rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex: 1;
    }
</style>
