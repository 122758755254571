<script lang="ts">
    import icon from "../assets/pause-icon.svg";
</script>

<img src={icon} />

<style>
    img {
        height: 0.8em;
        transform: translateY(0.025em);
    }
</style>
