<script lang="ts">
    import { location, push } from "svelte-spa-router";
    import { sharedLists } from "../stores/atopic-store";
    import Button from "./Button.svelte";
    import ButtonGroup from "./ButtonGroup.svelte";
    import TopNavigation from "./TopNavigation.svelte";
    $: activeRoute = (route: string) => $location.startsWith(route);
    $: show =
        ($sharedLists.size > 0 && $location.startsWith("/my-atopics")) ||
        $location.startsWith("/list");
</script>

{#if show}
    <TopNavigation>
        <ButtonGroup scroll>
            <Button
                secondary
                caps={false}
                active={activeRoute("/my-atopics")}
                on:click={() => !activeRoute("/my-atopics") && push("/my-atopics")}
            >
                myAtopics
            </Button>

            {#each Array.from($sharedLists.values()) as list}
                {@const listLink = `/list/${list.data}`}
                <Button
                    secondary
                    caps={false}
                    active={activeRoute(listLink)}
                    on:click={() => !activeRoute(listLink) && push(listLink)}
                >
                    {list.name}
                </Button>
            {/each}
        </ButtonGroup>
    </TopNavigation>
{/if}
