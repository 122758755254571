<script lang="ts">
    import type { Atopic } from "../types/Atopic";
    import { encode } from "../util/share";
    import clipboard from "clipboardy";
    import AnimatedPromise from "./AnimatedPromise.svelte";
    import Button from "./Button.svelte";

    export let atopic: Atopic;
    let promise: Promise<void> | undefined;
    const wait = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

    async function handleShare(atopic: Atopic) {
        const sharePart = encode({ atopic });
        const url = `${window.location.origin}/#/share/${sharePart}`;
        await clipboard.write(url);
        await wait(2000);
    }
</script>

<AnimatedPromise {promise}>
    <Button secondary on:click={() => (promise = handleShare(atopic))}><slot /></Button>
    <svelte:fragment slot="alt">
        <Button secondary caps={false}><slot name="shared" /></Button>
    </svelte:fragment>
</AnimatedPromise>
