<script lang="ts">
    import { tweened } from "svelte/motion";
    import { fade } from "svelte/transition";
    import {
        abort,
        atopicProgressState,
        completeTask,
        MAX_DURATION,
        timeOffset,
        timePassed,
        backgroundAudioMuted,
    } from "../stores/progress-store";
    import Audio from "./Audio.icon.svelte";
    import Button from "./Button.svelte";
    import X from "./X.icon.svelte";

    export let hasAudio = false;

    const milliseconds = tweened($timePassed + $timeOffset);

    $: if ($atopicProgressState === "started") milliseconds.set(0, { duration: 0 });

    $: milliseconds.set($timePassed + $timeOffset, { duration: 200 });

    $: minutes = Math.floor($milliseconds / 1000 / 60)
        .toFixed(0)
        .padStart(2, "0");
    $: seconds = Math.floor(($milliseconds / 1000) % 60)
        .toFixed(0)
        .padStart(2, "0");

    function handleAbort() {
        if ($timePassed + $timeOffset > MAX_DURATION) {
            completeTask();
        } else {
            abort();
        }
    }
</script>

{#if $atopicProgressState === "started"}
    <div transition:fade={{ duration: 300 }}>
        <span>
            {minutes}:{seconds}
        </span>
        {#if hasAudio}
            <Button glyph on:click={() => ($backgroundAudioMuted = !$backgroundAudioMuted)}>
                <Audio toggle={!$backgroundAudioMuted} />
                <!-- {$backgroundAudioMuted ? "!A" : "A"} -->
            </Button>
        {/if}
        <Button glyph on:click={handleAbort}><X /></Button>
    </div>
{/if}

<style>
    div {
        position: relative;
        margin-left: auto;
        z-index: 8;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    span {
        font-feature-settings: "tnum";
    }
</style>
