import { cubicOut } from "svelte/easing";

export default function morph(
    node: Element,
    { initialScaleX, initialScaleY } = {
        initialScaleX: 1,
        initialScaleY: 1,
    }
) {
    const existingTransform = getComputedStyle(node).transform.replace("none", "");
    const animatedScaleX = 1 - initialScaleX;
    const animatedScaleY = 1 - initialScaleY;

    return {
        delay: 0,
        duration: 200,
        easing: cubicOut,
        css: (t: number, u: number) => {
            // return ``;
            return `transform: ${existingTransform} scaleX(${
                initialScaleX + animatedScaleX * t
            }) scaleY(${initialScaleY + animatedScaleY * t})`;
        },
    };
}
