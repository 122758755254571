import { derived, get, writable } from "svelte/store";
import type { Atopic } from "../types/Atopic";
import { createTimer } from "../util/timer";
import { finish as finishAtopic } from "./atopic-store";

export type AtopicProgressState =
    | "initial"
    | "started"
    | "completed-task"
    | "upload-reaction"
    | "done";

const _atopicProgressState = writable<AtopicProgressState>("initial");
const timer = createTimer({ interval: 1000 });
const startTime = writable<Date | null>(null);
export const timeOffset = writable(0);
export const lastFastForwarded = writable<Date | null>(null);
export const duration = writable(0);
export const backgroundAudioMuted = writable(false);

const durationDict: Record<string, number> = {
    aboutFiveMinutes: 5 * 60 * 1000,
    aboutThirtyMinutes: 30 * 60 * 1000,
    greaterThanThirtyMinutes: 30 * 60 * 1000,
};

export async function start(atopic: Atopic) {
    if (!durationDict[atopic.duration]) throw "Duration not defined";
    duration.set(durationDict[atopic.duration]);
    startTime.set(new Date());
    timeOffset.set(0);
    _atopicProgressState.set("started");
}

export async function completeTask() {
    _atopicProgressState.set("completed-task");
}

export async function finish(atopic: Atopic) {
    finishAtopic(atopic.id);
    _atopicProgressState.set("done");
}

export function initialize(atopic: Atopic) {
    _atopicProgressState.set(atopic.done ? "done" : "initial");
}

export function abort() {
    _atopicProgressState.set("initial");
}

export function fastForward() {
    timeOffset.update((time) => time + 20 * 1000);
    lastFastForwarded.set(new Date());
}

// make readable outside of module
export const atopicProgressState = derived(_atopicProgressState, (s) => s);

export const timePassed = derived([timer, startTime], ([timer, startTime]) => {
    if (!startTime) return 0;
    return Math.max(0, timer.getTime() - startTime.getTime());
});

export const FAST_FORWARD_COOLDOWN = 1000 * 2;

export const isFastForwarding = derived(
    [timer, lastFastForwarded],
    ([timer, lastFastForwarded]) => {
        if (!lastFastForwarded) return false;
        if (timer.getTime() - lastFastForwarded.getTime() > FAST_FORWARD_COOLDOWN) return false;
        return true;
    }
);

export const MAX_DURATION = 1000 * 60 * 30;

export const progress = derived(
    [timePassed, duration, timeOffset],
    ([timePassed, duration, timeOffset]) =>
        Math.min(1, Math.max(0, (timePassed + timeOffset) / Math.min(duration, MAX_DURATION)))
);

timePassed.subscribe((timePassed) => {
    if (get(atopicProgressState) !== "started") return;
    if (timePassed + get(timeOffset) < get(duration)) return;
    if (get(isFastForwarding)) return;
    _atopicProgressState.set("completed-task");
});
