<script lang="ts">
    import icon from "../assets/forward-icon.svg";
</script>

<img src={icon} on:click />

<style>
    img {
        height: 0.92em;
    }
</style>
