<script lang="ts">
    import { createEventDispatcher, onMount } from "svelte";
    import Dialog from "./Dialog.svelte";
    import { uploadAudioReaction, uploadTextReaction } from "../stores/reaction-store";
    import type { Atopic } from "../types/Atopic";
    import Button from "./Button.svelte";
    import ButtonGroup from "./ButtonGroup.svelte";
    import { v4 as uuidv4 } from "uuid";
    import { createRecorder } from "../util/recorder";
    import Audioplayer from "./Audioplayer.svelte";
    import Record from "./Record.icon.svelte";
    import CardContainer from "./CardContainer.svelte";
    import Stop from "./Stop.icon.svelte";
    import BarGraph from "./BarGraph.svelte";

    const dispatch = createEventDispatcher();
    const { record, stop, isRecording, getTimeDomainData: dataProvider } = createRecorder();

    export let atopic: Atopic;
    let idempotencyToken: string = uuidv4();
    let uploadRequest: Promise<{ uuid: string } | undefined>;
    let uploadError = false;
    let permissionError = false;
    let recording: Promise<Blob | undefined>;
    let dialogOpen = true;
    let blob: Blob | undefined;

    $: blob, (idempotencyToken = uuidv4());

    function handleDialogClose() {
        stop();
        dialogOpen = false;
        dispatch("close");
    }

    onMount(() => {
        // handleStart();
        return handleStop;
    });

    async function upload() {
        if (!blob) return;
        uploadError = false;
        try {
            const result = await uploadAudioReaction({ atopic, file: blob, idempotencyToken });
            dispatch("success", result);
            handleDialogClose();
            return result;
        } catch (e: any) {
            uploadError = true;
            throw e;
        }
    }

    async function handleStart() {
        if ($isRecording) return;
        blob = undefined;
        permissionError = false;
        recording = record();
        try {
            blob = await recording;
        } catch (e) {
            permissionError = true;
        }
    }

    async function handleStop() {
        stop();
    }

    function handleSubmit() {
        if (!blob) return;
        uploadRequest = upload();
    }
</script>

<Dialog
    open={dialogOpen}
    error={uploadError
        ? "Leider ist etwas schief gelaufen"
        : permissionError
        ? "Konnte nicht auf das Mikrofon zugreifen"
        : undefined}
>
    {#if !recording}
        <CardContainer />
    {/if}
    {#await recording}
        <BarGraph {dataProvider} />

        <!-- <CardContainer>
                now recording...
            </CardContainer> -->
    {:then blob}
        {#if blob}
            {@const url = URL.createObjectURL(blob)}
            <Audioplayer {url} mime={blob.type} loop={false} autoplay={false} />
        {/if}
    {:catch error}
        <CardContainer />
    {/await}
    <svelte:fragment slot="controls">
        <ButtonGroup center>
            {#if !uploadRequest}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                {#if !$isRecording}
                    <Button on:click={handleStart} glyph><Record /></Button>
                {:else}
                    <Button on:click={handleStop} glyph><Stop /></Button>
                {/if}
                <Button on:click={handleSubmit} disabled={!blob}>Hochladen</Button>
            {/if}
            {#await uploadRequest}
                lädt...
            {:catch}
                <Button secondary on:click={handleDialogClose}>Abbrechen</Button>
                <Button on:click={handleSubmit}>Nochmal</Button>
            {/await}
        </ButtonGroup>
    </svelte:fragment>
</Dialog>

<style>
</style>
