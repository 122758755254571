import { v4 as uuidv4 } from "uuid";
const CACHE_NAME = "2304982340234098234098";

export function createChannelRemote<T>(channel: string) {
    const receivedUUIDs = new Set<string>();
    if (!channel.match(/[\-a-z]+/)) throw "invalid channel name";
    return {
        async send(message: T) {
            try {
                const result = await fetch("/api/share", {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({ message, date: new Date(), uuid: uuidv4() }),
                });
            } catch (e) {}
        },
        async receive(maxAge?: number) {
            try {
                const response = await fetch("/api/share");
                if (!response) {
                    return null;
                }

                const responseBody: Partial<{
                    message: string;
                    uuid: string;
                    date: string;
                }> = await response.json();
                if (!responseBody?.message) throw "No message body present";
                if (!responseBody?.uuid) throw "No uuid present";
                if (!responseBody?.date) throw "No date present";
                const { date, message, uuid } = responseBody;
                if (receivedUUIDs.has(uuid)) return null;
                receivedUUIDs.add(uuid);
                if (maxAge) {
                    if (new Date().getTime() - new Date(date).getTime() > 1000 * maxAge) {
                        throw "Message to old";
                    }
                }
                return message;
            } catch (error) {
                // Gotta catch 'em all
                console.log("receive message error:", { error });
                return null;
            } finally {
            }
        },
    };
}

export function createChannel<T>(channel: string) {
    if (!channel.match(/[\-a-z]+/)) throw "invalid channel name";
    return {
        async send(message: T) {
            try {
                const cache = await caches.open(CACHE_NAME);
                const responseBody = JSON.stringify({ message, date: new Date() });
                const response = new Response(responseBody);
                await cache.put(`/${channel}`, response);
                console.log("send message", message);
            } catch (error) {
                // It's up to you how you resolve the error
                console.log("send message error:", { error });
            }
        },
        async receive(maxAge?: number): Promise<T | null> {
            try {
                const cache = await caches.open(CACHE_NAME);
                console.log(await caches.keys());
                console.log(CACHE_NAME, await cache.keys());
                const response = await cache.match(`/${channel}`);

                if (!response) {
                    return null;
                }

                const responseBody = await response.json();
                await cache.delete(channel);
                if (!responseBody?.message) throw "No message body present";
                if (responseBody?.date) {
                    if (
                        new Date().getTime() - new Date(responseBody.date).getTime() >
                        1000 * maxAge
                    ) {
                        throw "Message to old";
                    }
                }
                return responseBody.message;
            } catch (error) {
                // Gotta catch 'em all
                console.log("receive message error:", { error });
                return null;
            } finally {
            }
        },
    };
}
