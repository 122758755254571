import { Mp3MediaRecorder } from "mp3-mediarecorder";
import { derived, writable } from "svelte/store";

const worker = new Worker(new URL("./worker.ts", import.meta.url), {
    type: "module",
});

export function createRecorder() {
    let mediaStream: MediaStream = null;
    let recorder: Mp3MediaRecorder = null;
    let blobs: Blob[] = null;
    let isRecording = writable(false);
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 128;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    function handleStream(stream: MediaStream) {
        const source = audioContext.createMediaStreamSource(stream);
        mediaStream = stream;
        source.connect(analyser);
    }
    return {
        getTimeDomainData() {
            analyser.getByteTimeDomainData(dataArray);
            // analyser.getByteFrequencyData(dataArray);
            return dataArray;
        },
        isRecording: derived(isRecording, (isRecording) => isRecording),
        stop() {
            recorder?.stop();
            recorder = undefined;
        },
        async record() {
            if (recorder) return;
            return new Promise<Blob>((resolve, reject) => {
                navigator.mediaDevices
                    .getUserMedia({
                        audio: {
                            echoCancellation: true,
                            noiseSuppression: true,
                            autoGainControl: true,
                            channelCount: 1,
                        },
                        video: false,
                    })
                    .then(
                        (stream) => {
                            recorder = new Mp3MediaRecorder(stream, { worker, audioContext });
                            handleStream(stream);

                            recorder.start();

                            recorder.onstart = (e) => {
                                isRecording.set(true);
                                blobs = [];
                            };

                            recorder.ondataavailable = (e) => {
                                console.log("ondataavailable", e);
                                blobs.push(e.data);
                            };

                            recorder.onstop = (e) => {
                                console.log("onstop", e);
                                mediaStream.getTracks().forEach((track) => {
                                    if (track.readyState == "live") {
                                        track.stop();
                                    }
                                });

                                isRecording.set(false);

                                const mp3Blob = new Blob(blobs, { type: "audio/mpeg" });
                                resolve(mp3Blob);
                                // const mp3BlobUrl = URL.createObjectURL(mp3Blob);
                                // const audio = new Audio();
                                // audio.controls = true;
                                // audio.src = mp3BlobUrl;
                                // recordings.appendChild(audio);
                            };

                            // recorder.onpause = (e) => {
                            //     console.log("onpause", e);
                            //     resumeButton.classList.remove("is-disabled");
                            //     pauseButton.classList.add("is-disabled");
                            // };

                            // recorder.onresume = (e) => {
                            //     console.log("onresume", e);
                            //     resumeButton.classList.add("is-disabled");
                            //     pauseButton.classList.remove("is-disabled");
                            // };

                            recorder.onerror = (e) => {
                                recorder;
                                console.error("onerror", e);
                                isRecording.set(false);

                                reject(e);
                            };
                        },
                        (reason) => {
                            reject(reason.message);
                            isRecording.set(false);

                            console.warn(
                                "Could not get microphone access.\nError:",
                                reason.message
                            );
                        }
                    );
            });
        },
    };
}
