<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Button from "./Button.svelte";

    let input: HTMLInputElement;
    const dispatch = createEventDispatcher();

    export let accept: string | undefined;

    function handleClick() {
        if (!input) return;
        input.click();
    }

    function handleChange(e: Event) {
        // @ts-expect-error
        const file = e.target?.files?.[0];
        if (!file) return;
        dispatch("file", { file });
    }
</script>

<Button on:click={handleClick}><slot /></Button>
<input type="file" {accept} id="file-input" bind:this={input} on:change={handleChange} />

<style>
    input {
        display: none;
    }
</style>
