<script lang="ts">
    import off from "../assets/audio-off-icon.svg";
    import on from "../assets/audio-on-icon.svg";

    export let toggle = true;
</script>

<img src={toggle ? on : off} alt="toggle buttons for audio" />

<style>
    img {
        height: 0.8em;
        transform: translateY(0.025em);
    }
</style>
