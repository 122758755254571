<div><slot /></div>

<style>
    div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: max-content;
        max-width: 90vw;
        text-align: center;
        font-size: 1.5em;
        line-height: 1.3em;
        font-weight: bold;
        transform: translate3d(-50%, -50%, 0);
    }
</style>
