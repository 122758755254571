import * as localforage from "localforage";
import { writable } from "svelte/store";
import { v4 as uuidv4 } from "uuid";

export let userId: string;
export let agbRead = writable<null | boolean>(null);

async function getOrSetUserId() {
    userId = await localforage.getItem("uid");
    if (!userId) {
        userId = uuidv4();
        localforage.setItem("uid", userId);
    }
}
getOrSetUserId();

async function initAgbState() {
    const value = await localforage.getItem<boolean>("agb");
    agbRead.set(value === null ? false : value);
}

initAgbState();

agbRead.subscribe(async (_agbRead) => {
    if (_agbRead === null) return;
    await localforage.setItem("agb", _agbRead);
});
