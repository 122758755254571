<script lang="ts">
    export let credit: String;
</script>

<div class="credit">
    {credit}
</div>

<style>
    .credit {
        padding: 1rem 1rem 1rem 1rem;
        text-align: center;
    }
</style>
