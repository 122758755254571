<script lang="ts">
    import type { LocalReaction, Reaction } from "../types/Reaction";
    import Image from "./Image.svelte";

    export let reactions: Readonly<(Reaction | LocalReaction)[]> = [];

    export let span = 4;
    $: _span = Math.min(span, reactions.length);
    export let index = 0;

    function calculateIndex(sp: number) {
        // span cant be larger then the whole array
        const i = index - Math.floor(_span / 2) + sp;
        return i < 0 ? reactions.length + i : i % reactions.length;
    }
</script>

{#each { length: _span } as _, i}
    {@const reaction = reactions[calculateIndex(i)]}
    {#if !reaction?.local}
        {#if reaction?.type === "image-reaction" && reaction.image}
            <Image preload image={reaction.image} />
        {:else if reaction?.type === "audio-reaction"}
            <!-- dont preload audio as it uses a range header -->
            <!-- <link rel="preload" as="source" src={reaction.audio.url} /> -->
        {/if}
    {/if}
{/each}
