<script lang="ts">
    import { currentLocale } from "../stores/atopic-store";
    import DataLoader from "../lib/DataLoader.svelte";

    export let page: string;
    export let scrollable = false;
</script>

<DataLoader url={`/api/${$currentLocale}/pages/${page}`} let:data let:error>
    {#if data}
        <div class:scrollable>
            <h1>{data.title}</h1>
            {@html data.text}
        </div>
    {/if}
</DataLoader>

<style>
    h1 {
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
        margin: 0.5rem 0;
        hyphens: auto;
    }
    div {
        padding: env(safe-area-inset-top) 0.5rem 3rem 0.5rem;
        line-height: 1.1em;
    }
    div.scrollable {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    div :global(p) {
        margin-bottom: 0.5rem;
        hyphens: auto;
    }

    div :global(a) {
        word-wrap: break-word;
        text-decoration: underline;
    }

    div :global(ol) {
        padding-left: 1.2rem;
    }

    div :global(ol li) {
        padding-left: 0rem;
        margin-bottom: 0.5rem;
    }

    div :global(ol hr) {
        display: none;
    }

    div :global(hr) {
        border: none;
        border-color: inherit;
        border-bottom: 2px solid;
        margin-bottom: 0.5rem;
    }
</style>
