<script>
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();
    export let active = false;
    export let caps = true;
    export let secondary = false;
    export let alignRight = false;
    export let glyph = false;
    export let disabled = false;
    export let id = null;
    export let shrink = false;

    function handleClick() {
        if (disabled) return;
        dispatch("click");
    }

    function handleKeydown() {
        if (disabled) return;
        dispatch("keydown");
    }
</script>

<div
    class="button-wrapper"
    {id}
    on:click={handleClick}
    on:keydown={handleKeydown}
    role="button"
    class:align-right={alignRight}
    class:shrink
>
    <span
        class="button"
        class:disabled
        class:glyph
        class:active
        class:no-caps={!caps}
        class:secondary><slot /></span
    >
</div>

<style>
    .button-wrapper {
        width: max-content;
        display: inline-block;
        padding: 0.5rem;
        margin: -0.5rem;
    }

    .button-wrapper.shrink {
        min-width: 0;
    }

    .button-wrapper.shrink > .button {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .button.active {
        background: rgb(170, 170, 170);
    }

    .button.glyph,
    .button > :global(.glyph) {
        font-size: 1.344rem;
    }

    .button > :global(.glyph) {
        vertical-align: baseline;
    }

    .button-wrapper.align-right {
        margin-left: auto;
    }

    .button.secondary {
        color: var(--color-inactive);
        background: rgb(215, 215, 215);
    }

    .button.disabled {
        color: rgb(170, 170, 170);
        background: rgb(215, 215, 215);
    }

    .button.secondary.active {
        color: black;
        background: rgb(170, 170, 170);
    }

    .button:not(.disabled):active {
        transform: scale(0.95);
    }

    .button.no-caps {
        text-transform: none;
    }

    .button {
        display: inline-block;
        font-size: 1.1rem;
        font-weight: normal;
        line-height: 1.9rem;
        border-radius: 5vw;
        background: rgb(210, 210, 210);
        padding: 0rem 1rem;
        text-transform: uppercase;
        user-select: none;
        transition: background 200ms, transform 50ms;
    }
</style>
