<script lang="ts">
    import VisibilityChange from "svelte-visibility-change";
    import { createEventDispatcher, onDestroy } from "svelte";
    import Error from "./Error.svelte";
    import { error as createError } from "../util/vercel-api-helpers";
    import Spinner from "./Spinner.svelte";

    const dispatch = createEventDispatcher();
    let controller = new AbortController();

    export let url: string;
    export let maxRetries = 1;

    let loaded = false;
    let error: string | undefined;
    let data: any;
    let timeout: number;

    function reset() {
        loaded = false;
        data = undefined;
        error = undefined;
    }

    async function fetchData(_url: string, _retryCount = 0) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = undefined;
        }
        controller?.abort();
        controller = new AbortController();
        const signal = controller.signal;
        try {
            const req = await fetch(_url, {
                method: "get",
                signal,
                headers: {
                    "content-type": "application/json",
                },
            });
            const json = await req.json();
            if (req.status === 200) {
                data = json;
                dispatch("data", { data: json });
                error = undefined;
                loaded = true;
            } else {
                // log error from api
                console.error(json?.message);
                throw createError(json?.status, json?.message);
            }
        } catch (e) {
            controller = undefined;
            if (e?.name === "AbortError") return;
            if (_retryCount < maxRetries && e?.status >= 500) {
                timeout = window.setTimeout(
                    () => fetchData(_url, _retryCount + 1),
                    300 + _retryCount * _retryCount * 2000
                );
            } else {
                error = e?.message || "Network Error";
                dispatch("error", e);
            }
        } finally {
            controller = undefined;
        }
    }

    export const reload = () => {
        return fetchData(url, 0);
    };

    onDestroy(() => {
        controller?.abort();
        if (timeout) window.clearTimeout(timeout);
    });

    let visible = false;

    $: {
        if (visible) {
            fetchData(url);
        }
    }

    $: url, reset();
</script>

<VisibilityChange bind:visible />
{#if $$slots.default}
    {#if loaded}
        <slot {data} {error} />
    {:else if error}
        <Error>{error}</Error>
    {:else}
        <div>
            <Spinner />
        </div>
    {/if}
{/if}

<style>
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>
