<script lang="ts">
    import { onMount } from "svelte";
    import { pwaInfo } from "virtual:pwa-info";
    const intervalMS = 60 * 60 * 1000;

    onMount(async () => {
        let interval: number;
        if (pwaInfo) {
            const { registerSW } = await import("virtual:pwa-register");
            registerSW({
                onRegisteredSW(swUrl, r) {
                    console.log("registered");
                    async function check() {
                        console.log("sw: check for update", swUrl);
                        if (!(!r.installing && navigator)) return;

                        if ("connection" in navigator && !navigator.onLine) return;

                        const resp = await fetch(swUrl, {
                            cache: "no-store",
                            headers: {
                                cache: "no-store",
                                "cache-control": "no-cache",
                            },
                        });

                        if (resp?.status === 200) await r.update();
                        console.log("sw: updated", swUrl);
                    }
                    if (r) {
                        interval = window.setInterval(check, intervalMS);
                        document.addEventListener("visibilitychange", () => {
                            if (!document.hidden) {
                                check();
                            }
                        });
                    }
                },
            });
        }
        return () => {
            window.clearInterval(interval);
        };
    });
</script>
