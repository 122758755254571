<script lang="ts">
    import { onMount } from "svelte";
    import { push } from "svelte-spa-router";
    import { getRandomAtopic } from "../stores/atopic-store";

    onMount(async () => {
        const atopic = getRandomAtopic();
        if (atopic) {
            await push(`/atopic/${atopic.id}`);
        }
    });
</script>
