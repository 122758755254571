<script lang="ts">
    import type { Reaction } from "../../types/Reaction";
    import Audioplayer from "../Audioplayer.svelte";
    import CardContainer from "../CardContainer.svelte";
    import Image from "../Image.svelte";

    export let reaction: Reaction;
    export const draggable = true;
    export const ditchable = true;
    export const morphable = true;
</script>

{#if reaction}
    <div>
        {#if reaction.type === "text-reaction"}
            <CardContainer>
                {@html reaction.text}
            </CardContainer>
        {:else if reaction.type === "image-reaction" && reaction.image}
            <Image image={reaction.image} />
        {:else if reaction.type === "audio-reaction" && reaction.audio}
            {#key reaction.audio.url}
                <Audioplayer
                    url={reaction.audio.url}
                    mime={reaction.audio.mime}
                    loop={false}
                    autoplay={true}
                />
            {/key}
        {/if}
    </div>
{:else}
    <CardContainer transparent centerContent>
        Es gibt zu diesem Atopic noch<br /> keine Reaktionen`
    </CardContainer>
{/if}

<style>
    div > :global(img) {
        /* min-height: 50vw; */
        max-width: 90vw;
        height: auto;
        max-height: 50vh;
        object-fit: cover;
        background: white;
    }
</style>
