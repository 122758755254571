<script lang="ts">
    export let scroll = false;
    export let transparent = false;
    export let centerContent = false;
</script>

<div
    class="text-container"
    class:scroll
    class:transparent
    class:center-content={centerContent}
    on:click
>
    <slot />
</div>

<style>
    .text-container {
        max-height: 120vw;
        min-height: 50vw;
        overflow: hidden;
        width: 95vw;
        padding: 0.5rem;
        background: white;
        line-height: 1.2em;
        position: relative;
    }

    .text-container.center-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .text-container.transparent {
        background: none;
    }

    .text-container.scroll {
        overflow: scroll;
        touch-action: pan-y;
    }
</style>
