<script lang="ts">
    import icon from "../assets/x-icon-2.svg";
</script>

<img src={icon} />

<style>
    img {
        height: 1rem;
        transform: translateY(0.05rem);
    }
</style>
