<script lang="ts">
    import { fly } from "svelte/transition";

    export let promise: Promise<void> | undefined;
</script>

<div class="container">
    {#if !promise}
        <div
            in:fly|local={{ x: -30, duration: 200 }}
            out:fly|local={{ x: 30, duration: 200, delay: 100 }}
        >
            <slot {promise} />
        </div>
    {/if}
    {#await promise}
        <div
            in:fly|local={{ x: -30, duration: 200, delay: 100 }}
            out:fly|local={{ x: 30, duration: 200, delay: 100 }}
        >
            <slot name="alt" />
        </div>
    {:then data}
        <div
            in:fly|local={{ x: -30, duration: 200 }}
            out:fly|local={{ x: 30, duration: 200, delay: 100 }}
        >
            <slot {promise} />
        </div>
    {/await}
</div>

<style>
    .container {
        position: relative;
        display: grid;
        grid-template: "container";
        place-items: self-start;
        place-content: center;
    }
    .container > div {
        grid-area: container;
    }
</style>
