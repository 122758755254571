<script lang="ts">
    import type { Atopic } from "../../types/Atopic";
    import AtopicCredit from "../AtopicCredit.svelte";
    import CardContainer from "../CardContainer.svelte";

    export let atopic: Atopic;
    export const ditchable = true;
    export const draggable = true;
</script>

<CardContainer scroll>
    {@html atopic.explanation}
    {#if atopic.credit}
        <AtopicCredit credit={atopic.credit} />
    {/if}
</CardContainer>

<style>
</style>
