<script lang="ts">
    import type { Atopic } from "../../types/Atopic";
    import Image from "../Image.svelte";

    let headline;
    export let atopic: Atopic;
    export const ditchable = true;
    export const draggable = true;
    export let fullWidth = false;
    export let noImage = false;

    const colorMap = {
        atHome: {
            aboutFiveMinutes: ["#ffff00", "#9baac8"],
            aboutThirtyMinutes: ["#ff0000", "#dcb4ff"],
            greaterThanThirtyMinutes: ["#500f00", "#ff0000"],
        },
        outside: {
            aboutFiveMinutes: ["#ffc896", "#005000"],
            aboutThirtyMinutes: ["#e18229", "#ffff00"],
            greaterThanThirtyMinutes: ["#7b8800", "#ffacff"],
        },
        somewhereElse: {
            aboutFiveMinutes: ["#ffff00", "#9baac8"],
            aboutThirtyMinutes: ["#ff0000", "#dcb4ff"],
            greaterThanThirtyMinutes: ["#500f00", "#ff0000"],
        },
        everywhere: {
            aboutFiveMinutes: ["#ffff00", "#9baac8"],
            aboutThirtyMinutes: ["#ff0000", "#dcb4ff"],
            greaterThanThirtyMinutes: ["#500f00", "#ff0000"],
        },
    };

    $: [backgroundColor, color] = colorMap[atopic.location][atopic.duration];
</script>

<div
    class="container"
    style={`--bg-color: ${backgroundColor}; --fg-color: ${color}`}
    class:has-cover-image={!!atopic.coverImage && !noImage}
    class:full-width={fullWidth}
    on:click
    on:keydown
>
    <div class="h1-container">
        <div>
            <h1 bind:this={headline}>{atopic.title}</h1>
        </div>
    </div>
    {#if atopic.coverImage && !noImage}
        <Image image={atopic.coverImage} />
    {/if}
</div>

<style>
    .container {
        --width: 90vw;
        width: var(--width);
        min-height: 50vw;
        background: var(--bg-color, white);
        color: var(--fg-color, black);
        display: grid;
        grid-template: "container";
        place-items: center;
        place-content: center;
        overflow: hidden;
        position: relative;
    }

    .container.full-width {
        --width: calc(100vw - 1rem);
    }

    .h1-container {
        padding: 0.5rem;
        z-index: 1;
    }

    h1 {
        text-align: center;
        hyphens: auto;
        font-size: 2.2rem;
    }

    .container.has-cover-image h1 {
        /* font-size is set by fitty */
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        font-family: "Inter";
    }

    .container > * {
        grid-area: container;
        width: var(--width);
    }

    .container > :global(img) {
        position: absolute;
        width: var(--width);
        height: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
</style>
