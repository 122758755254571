<script lang="ts">
    let dialog: HTMLDialogElement;
    export let open = false;
    export let top = false;
    export let reduceWidth = false;
    export let error: string | undefined = undefined;

    $: {
        if (open) dialog?.showModal();
        else dialog?.close();
    }
</script>

{#if open}
    <dialog bind:this={dialog} class:top class:error>
        <div class="content" class:reduce-width={reduceWidth}>
            <slot />
        </div>
        <div class="controls">
            {#if error}
                <div class="error-message">
                    {error}
                </div>
            {/if}
            <slot name="controls" />
        </div>
    </dialog>
{/if}

<style>
    dialog.top {
        top: 2rem;
        transform: translateX(-50%) translateY(0%);
    }

    dialog:modal {
        max-width: 100vw;
    }
    dialog {
        border: none;
        position: fixed;
        transform: translateX(-50%) translateY(-50%);
        max-width: 100vw;
        width: 95vw;
        left: 50vw;
        top: 50vh;
        background: transparent;
        overflow: hidden;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

    }

    .content {
        border-radius: 1rem;
        border: 1px solid blue;
        overflow: hidden;
        margin: 0 auto 0.66rem auto;
    }

    .content.reduce-width {
        width: fit-content;
    }

    dialog[open] {
    }

    dialog::backdrop {
        -webkit-backdrop-filter: blur(0.4rem);
        backdrop-filter: blur(0.4rem);
        background: rgba(255, 255, 255, 0.3);
    }

    dialog.error > .content {
        border-color: red;
    }

    .error-message {
        margin-bottom: 0.66rem;
        color: red;
        text-align: center;
    }
</style>
