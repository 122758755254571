<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import type { AtopicProgressState } from "../stores/progress-store";
    import type { Atopic } from "../types/Atopic";
    import type { LocalReaction, Reaction } from "../types/Reaction";
    import Button from "./Button.svelte";
    import FileSelectButton from "./FileSelectButton.svelte";

    const dispatch = createEventDispatcher();

    export let face: string;
    export let atopic: Atopic;
    export let atopicProgress: AtopicProgressState;
    export let reactions: Readonly<(LocalReaction | Reaction)[]>;
    export let reactionIndex: number;
</script>

{#if face === "instruction" || face === "explanation"}
    {#if ["initial", "done"].includes(atopicProgress)}
        {#if atopic.done}
            <Button on:click={() => dispatch("atopicStart")}>Nochmal</Button>
        {:else}
            <Button on:click={() => dispatch("atopicStart")}>Start</Button>
        {/if}
    {:else if atopicProgress === "completed-task" || atopicProgress === "started"}
        {#if atopic.mandatoryReactionMedium === "photo"}
            <FileSelectButton
                on:file={(e) =>
                    dispatch("reactionUpload", { type: "image-reaction", file: e.detail.file })}
                accept="image/png, image/jpg"
            >
                Bild machen
            </FileSelectButton>
        {:else if atopic.mandatoryReactionMedium === "audio"}
            <Button on:click={() => dispatch("reactionUpload", { type: "audio-reaction" })}>
                Audio aufnehmen
            </Button>
        {:else if atopic.mandatoryReactionMedium === "text"}
            <Button on:click={() => dispatch("reactionUpload", { type: "text-reaction" })}>
                Text schreiben
            </Button>
        {:else}
            <Button on:click={() => dispatch("atopicDone")}>Fertig</Button>
        {/if}
    {/if}
{:else if face === "reactions"}
    {#if reactions && reactions.length > 0}
        <span>{reactionIndex + 1}/{reactions.length}</span>
        <Button
            glyph
            alignRight
            on:click={() => dispatch("previousReaction")}
            disabled={reactions.length <= 1}
        >
            ←
        </Button>
        <Button glyph on:click={() => dispatch("nextReaction")} disabled={reactions.length <= 1}>
            →
        </Button>
    {/if}
{/if}

<style>
    span {
        color: var(--color-inactive);
    }
</style>
