import type { Atopic } from "../types/Atopic";
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string";
import type { AtopicList } from "../types/List";
import { userId } from "../stores/user-store";

export function encode({
    listName,
    atopic,
    atopics,
}: {
    listName?: string;
    atopics?: Atopic[];
    atopic?: Atopic;
}) {
    if (atopic) {
        return `atopic/${atopic.id}`;
    } else if (atopics && atopics.length > 0) {
        const data = {
            i: userId.slice(0, 8),
            n: listName,
            a: atopics.map((a) => a.id),
        };
        return compressToEncodedURIComponent(JSON.stringify(data));
    }
    throw "either atopics or atopic must be set";
}

export function decode(input: string, atopics: Atopic[]) {
    if (input.startsWith("atopic/")) {
        return {
            isList: false,
            link: input,
        } as const;
    } else {
        return {
            isList: true,
            list: decodeList(input, atopics),
        } as const;
    }
}

export function decodeList(input: string, atopics: Atopic[]): AtopicList {
    const data: { n?: string; a?: string[]; i?: string } = JSON.parse(
        decompressFromEncodedURIComponent(input)
    );
    if (!data.a || !data.i) throw "List invalid";
    return {
        userIdShort: data?.i,
        name: data?.n,
        atopics: data?.a
            .map((id) => atopics.find((a) => a.id === id))
            .filter((a) => a !== undefined),
        data: input,
    };
}

export function key(data: AtopicList) {
    return `${data.name}-${data.userIdShort}`;
}
