<script lang="ts">
    import type { BackendImage } from "../types/Atopic";

    export let image: BackendImage | { url: string | undefined };
    export let preload = false;
    const devicePixelRatio = Math.round(window.devicePixelRatio || 1);
    // @ts-expect-error
    $: src = image.url || image[`src${devicePixelRatio.toFixed(0)}x`];
</script>

{#if !preload}
    <img {src} alt="backend" on:load width={image?.width || null} height={image?.height || null} />
{:else}
    <link rel="preload" as="image" href={src} />
{/if}
