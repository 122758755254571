<script lang="ts">
    export let scroll = false;
    export let narrow = false;
    export let center = false;
    export let alignRight = false;
</script>

<div class="touch-container" class:narrow>
    <div class="button-group" class:scroll class:center class:align-right={alignRight}>
        <slot />
    </div>
</div>

<style>
    .button-group {
        display: flex;
        width: calc(100%);
        gap: 0.5rem;
        align-items: center;
    }

    .button-group.align-right {
        justify-content: flex-end;
    }

    .touch-container {
        margin: -0.5rem;
        padding: 0.5rem;
    }

    .touch-container.narrow {
        margin: -0.25rem;
        padding: 0.25rem;
    }

    .button-group.center {
        justify-content: center;
    }

    .button-group.scroll {
        white-space: nowrap;
        overflow: auto;
        overflow-y: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        margin: 0 -0.5rem;
        width: calc(100% + 1rem);
        padding: 0 0.5rem;
    }
    .button-group.scroll::-webkit-scrollbar {
        display: none;
    }

    .touch-container:not(:last-of-type) {
        margin-bottom: 0.5rem;
    }

    .touch-container.narrow:not(:last-of-type) {
        margin-bottom: 0.25rem;
    }
</style>
