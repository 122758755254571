import { sineIn, sineOut } from "svelte/easing";

export default function flip(node: Element, _params: { out: boolean; liftUp?: boolean }) {
    const params = { liftUp: true, ..._params };
    const existingTransform = getComputedStyle(node).transform.replace("none", "");
    const duration = 500;
    return {
        delay: params.out ? 0 : duration,
        duration: duration,
        easing: params.out ? sineIn : sineOut,
        css: (t: number, u: number) => {
            // let squish = (u: number, th = 0.2) =>
            // 	u <= th ? 0 : u >= 1 - th ? 1 : (u - th) * (1 / (1 - th - th));
            const squish = (u: number, th = 0.3) => (u <= th ? 0 : (u - th) * (1 / (1 - th)));
            const rotateTime = params.out ? squish(u) * -1 : squish(u);
            let css = "";
            css += `transform: ${existingTransform} translateZ(${
                u * 300 * (params.liftUp ? 1 : 0)
            }vw) rotateY(${rotateTime * 90}deg);`;
            css += "pointer-events: none";
            return css;
        },
    };
}
