function ServerError(status, message) {
    this.name = "ServerError";
    this.message = message;
    this.status = status;
    this.stack = new Error().stack;
}

export const error = (status, message) => {
    throw new ServerError(status, message);
};

export const vercelServerlessFunctionBoundary = (fn) => async (req, res) => {
    try {
        let handler = fn;
        if (typeof fn === "object") {
            handler = fn?.[req.method.toLowerCase()];
            if (!handler) error(404, `No ${req.method.toLowerCase()}-handler registered.`);
        }
        const response = await handler(req);
        res.status(200).json(response);
    } catch (error) {
        if (error.name !== "ServerError") throw error;
        console.error(error.name, error.status, error.message);
        console.error(error.stack);
        res.status(error.status).json({
            status: error.status,
            message: error.message,
        });
    }
};
