<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { push } from "svelte-spa-router";
    import { fly } from "svelte/transition";
    import type { Atopic } from "../types/Atopic";
    import Button from "./Button.svelte";
    import ButtonGroup from "./ButtonGroup.svelte";
    import FileSelectButton from "./FileSelectButton.svelte";
    import AtopicProgressControl from "./AtopicProgressControl.svelte";
    import TopNavigation from "./TopNavigation.svelte";

    export let atopic: Atopic;
    export let face: string;
    export let above: boolean = true;

    const dispatch = createEventDispatcher();

    function handleExplanation() {
        if (face !== "explanation") push(`/atopic/${atopic.id}/explanation`);
        else push(`/atopic/${atopic.id}/instruction`);
    }

    function handleReactions() {
        if (face !== "reactions") push(`/atopic/${atopic.id}/reactions`);
        else push(`/atopic/${atopic.id}/instruction`);
    }
</script>

{#if face !== "title" || atopic.done}
    <TopNavigation {above}>
        <ButtonGroup narrow>
            {#if atopic.explanation}
                <Button active={face === "explanation"} secondary on:click={handleExplanation}>
                    Beitext
                </Button>
            {/if}
            {#if atopic.done}
                <Button active={face === "reactions"} secondary on:click={handleReactions} shrink>
                    Reaktionen
                </Button>
                <!-- <Button alignRight glyph secondary>↺</Button> -->
            {/if}
            <AtopicProgressControl hasAudio={!!atopic.backgroundAudio} />
        </ButtonGroup>

        {#if face === "reactions"}
            <div transition:fly|local={{ x: -30, duration: 200 }}>
                <ButtonGroup scroll narrow>
                    <FileSelectButton
                        on:file={(e) =>
                            dispatch("reactionUpload", {
                                type: "image-reaction",
                                file: e.detail.file,
                            })}
                        accept="image/png, image/jpg"
                    >
                        Bild machen
                    </FileSelectButton>
                    <Button on:click={() => dispatch("reactionUpload", { type: "audio-reaction" })}>
                        Audio aufnehmen
                    </Button>
                    <Button on:click={() => dispatch("reactionUpload", { type: "text-reaction" })}>
                        Text schreiben
                    </Button>
                </ButtonGroup>
            </div>
        {/if}
    </TopNavigation>
{/if}
