import "./app.css";
import App from "./App.svelte";

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

const environment = import.meta.env.MODE;

if (environment !== "development") {
    // Initialize the Sentry SDK here
    Sentry.init({
        dsn: "https://9c3feb93e3a947caaa28f2a859b9f1db@o466779.ingest.sentry.io/4504237667057664",
        integrations: [new BrowserTracing()],
        environment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const app = new App({
    target: document.getElementById("app"),
});

export default app;
