<script lang="ts">
    import { onMount } from "svelte";

    export let dataProvider: () => Uint8Array;
    let canvas: HTMLCanvasElement;
    $: context = canvas?.getContext("2d");
    const WIDTH = 500;
    const HEIGHT = 200;

    function draw(data: Uint8Array) {
        context.fillStyle = "rgb(255, 255, 255)";
        context.fillRect(0, 0, WIDTH, HEIGHT);
        context.fillStyle = "rgb(0, 0, 0)";
        for (let i = 0; i < data.length; i++) {
            const barWidth = WIDTH / data.length;
            const barHeight = 5 + Math.abs(128 - data[i]) * 2;
            context.fillRect(
                i * barWidth + barWidth / 2 - 1,
                HEIGHT / 2 - barHeight / 2,
                2,
                barHeight
            );
        }
    }

    onMount(() => {
        let rid = requestAnimationFrame(function update() {
            draw(dataProvider());
            rid = requestAnimationFrame(update);
        });
        return () => cancelAnimationFrame(rid);
    });
</script>

<canvas bind:this={canvas} width={WIDTH} height={HEIGHT} />

<style>
    canvas {
        width: 100%;
        height: 100%;
    }
</style>
