<script lang="ts">
    import { agbRead } from "../stores/user-store";
    import { ditch } from "../transitions/ditch";
    import Button from "./Button.svelte";
    import CardContainer from "./CardContainer.svelte";

    let checked: boolean;
</script>

{#if $agbRead === true}
    <slot />
{:else}
    <div class="center" in:ditch={{ inOut: true }} out:ditch={{ inOut: false }}>
        <CardContainer>
            <div class="checkbox">
                <input id="agb" type="checkbox" bind:checked />
                <label for="agb">
                    Ich handle bei der Ausführung der Aufgaben/Übungen (Atopics)
                    eigenverantwortlich, werde hierbei Eigentumsrechte Dritter nicht verletzen und
                    die ordnungs- und gefahrenrechtlichen Vorgaben im öffentlichen Raum einhalten
                    und erkläre mich hiermit mit den <a href="https://atopic.app/#/datenschutz"
                        >Nutzungsbedingungen</a
                    >
                    einverstanden.
                </label>
            </div>
        </CardContainer>
        <Button disabled={!checked} on:click={() => ($agbRead = true)}>weiter</Button>
    </div>
{/if}

<style>
    .checkbox {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;
    }

    .checkbox > input {
        display: inline-block;
        min-width: 1rem;
    }

    a {
        text-decoration: underline;
    }

    .center {
        flex-direction: column;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .center :global(> :first-child) {
        margin-bottom: 0.5rem;
    }
</style>
