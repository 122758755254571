<script lang="ts">
    import ContentPage from "../lib/ContentPage.svelte";
    import HandContainer from "../lib/HandContainer.svelte";

    export let page: string;
    export let backgroundColor: string;
    export let foregroundColor: string;
</script>

<HandContainer {backgroundColor} {foregroundColor}>
    <ContentPage {page} scrollable />
</HandContainer>
