import type { SvelteComponent } from "svelte";
import type { RouteDefinition } from "svelte-spa-router";
// import {wrap} from 'svelte-spa-router/wrap;
import SingleAtopic from "./SingleAtopicPage.svelte";
import Title from "../lib/faces/Title.svelte";
import Instruction from "../lib/faces/Instruction.svelte";
import NotFound from "./NotFound.svelte";
import ExplanationSvelte from "../lib/faces/Explanation.svelte";
import ReactionsSvelte from "../lib/faces/Reactions.svelte";
import ExtraSvelte from "./Extra.svelte";
import MyAtopicsSvelte from "./MyAtopics.svelte";
import RandomAtopicSvelte from "./RandomAtopic.svelte";
import PageSvelte from "./Page.svelte";
import List from "./List.svelte";
import Share from "./Share.svelte";

// todo: use reqexp later
export const routes: RouteDefinition = {
    "/atopic/:atopicid/:face?": SingleAtopic,
    "/atopic": RandomAtopicSvelte,
    "/": RandomAtopicSvelte,
    "/extra": ExtraSvelte,
    "/my-atopics": MyAtopicsSvelte,
    "/list/*": List,
    "/share/*": Share,
    "/:page": PageSvelte,
    "*": NotFound,
};

export const faces: Record<string, ConstructorOfATypedSvelteComponent> = {
    title: Title,
    instruction: Instruction,
    explanation: ExplanationSvelte,
    reactions: ReactionsSvelte,
};
