<script lang="ts">
    import UpdateServiceWorker from "./lib/UpdateServiceWorker.svelte";
    import Router from "svelte-spa-router";
    import wrap from "svelte-spa-router/wrap";
    import { routes } from "./routes/routes";
    import DataLoader from "./lib/DataLoader.svelte";
    import { currentLocale, updateData } from "./stores/atopic-store";
    import AppGate from "./lib/AppGate.svelte";
    import InstallInstruction from "./routes/InstallInstruction.svelte";
    import ShareInstruction from "./routes/ShareInstruction.svelte";
    import Error from "./lib/Error.svelte";
    import ShareLinkListener from "./lib/ShareLinkListener.svelte";
    import MyAtopicsTopNavigation from "./lib/MyAtopicsTopNavigation.svelte";
    import WebNavigation from "./lib/WebNavigation.svelte";
    import PageWithHand from "./routes/PageWithHand.svelte";
    import AgbGate from "./lib/AgbGate.svelte";
</script>

<UpdateServiceWorker />
<AppGate>
    <ShareLinkListener />
    {#key $currentLocale}
        <div class="wrapper">
            <MyAtopicsTopNavigation />
            <DataLoader
                url={`/api/${$currentLocale}/atopics`}
                on:data={(e) => updateData(e.detail.data)}
            >
                <AgbGate>
                    <Router {routes} />
                </AgbGate>
            </DataLoader>
        </div>
    {/key}
    <svelte:fragment slot="not-supported-pwa">
        <Router
            routes={{
                "/impressum": wrap({
                    component: PageWithHand,
                    props: {
                        page: "imprint",
                        foregroundColor: "#ffff00",
                        backgroundColor: "#9baac8",
                    },
                }),
                "/datenschutz": wrap({
                    component: PageWithHand,
                    props: {
                        page: "data-policy",
                        foregroundColor: "#ffff00",
                        backgroundColor: "#9baac8",
                    },
                }),
                "/info": wrap({
                    component: PageWithHand,
                    props: {
                        page: "about",
                        foregroundColor: "#b4ff00",
                        backgroundColor: "#7b8800",
                    },
                }),
                "/share/*": ShareInstruction,
                "/download": InstallInstruction,
                "*": wrap({
                    component: PageWithHand,
                    props: {
                        page: "intro",
                        foregroundColor: "#ffff00",
                        backgroundColor: "#ff0000",
                    },
                }),
            }}
        />
        <WebNavigation />
    </svelte:fragment>
    <svelte:fragment slot="not-supported">
        <Error>Dein Browser wird leider nicht unterstützt</Error>
    </svelte:fragment>
</AppGate>
