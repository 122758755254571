<script lang="ts">
    import { like } from "../stores/atopic-store";
    import type { Atopic } from "../types/Atopic";
    import Button from "./Button.svelte";
    import Heart from "./Heart.icon.svelte";

    export let hideLikeButton = false;

    export let atopic: Atopic;
</script>

<div class="flex">
    <span>{atopic.locationLabel}</span>
    <span>{atopic.durationLabel}</span>
    {#if !hideLikeButton}
        <Button on:click={() => like(atopic.id, !atopic.liked)} glyph>
            <Heart active={atopic.liked} />
        </Button>
    {/if}
</div>

<style>
    .flex {
        display: flex;
        gap: 1rem;
        align-items: baseline;
        color: var(--color-inactive);
        justify-content: end;
    }
</style>
