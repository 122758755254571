<script lang="ts">
    import { onMount } from "svelte";
    import { push } from "svelte-spa-router";
    import AtopicMeta from "../lib/AtopicMeta.svelte";
    import BottomNavigation from "../lib/BottomNavigation.svelte";
    import Error from "../lib/Error.svelte";
    import Title from "../lib/faces/Title.svelte";
    import MyAtopicsTopNavigation from "../lib/MyAtopicsTopNavigation.svelte";
    import { atopics, sharedLists } from "../stores/atopic-store";
    import { ditch } from "../transitions/ditch";
    import type { Atopic } from "../types/Atopic";
    import type { AtopicList } from "../types/List";
    import { decode, decodeList, key } from "../util/share";

    export let params: { wild: string };
    const { wild: shareData } = params;
    let list: AtopicList | undefined;

    onMount(() => {
        try {
            const _list = decodeList(shareData, $atopics);
            sharedLists.update((map) => map.set(key(_list), _list));
            list = _list;
        } catch (e) {
            console.error(e);
        }
    });

    function handleClick(atopic: Atopic) {
        push(`#/atopic/${atopic.id}/instruction`);
    }
</script>

<div class="container">
    {#if list}
        {#each list?.atopics as atopic (atopic.id)}
            <div
                class="atopic"
                in:ditch={{ inOut: true, resetStyle: true }}
                out:ditch={{ inOut: false, resetStyle: true }}
            >
                <div class="meta">
                    <AtopicMeta {atopic} hideLikeButton />
                </div>
                <Title {atopic} fullWidth on:click={() => handleClick(atopic)} />
            </div>
        {/each}
    {:else}
        <Error>Diese Liste ist fehlerhaft</Error>
    {/if}
    <BottomNavigation noFilter />
</div>

<style>
    .atopic {
        margin-bottom: 0.5rem;
    }

    .meta {
        padding: 0.5rem 0;
    }

    .container {
        padding: calc(env(safe-area-inset-top) + 3rem) 0.5rem 0.5rem 0.5rem;
        line-height: 1.3em;
        overflow: scroll;
        overflow-x: hidden;
        width: 100%;
        height: 100%;
    }
</style>
