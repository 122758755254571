<script lang="ts">
    export let active = false;
    export let secondary = false;
</script>

<span class:active class:secondary>
    {active || secondary ? "♥" : "♡"}
</span>

<style>
    span {
        transition: background 200ms;
    }

    span.secondary {
        color: var(--color-inactive);
    }
    span.active {
        color: red;
    }

    span.active.secondary {
        color: black;
    }
</style>
