import { derived, type Readable, writable } from "svelte/store";
import type { Atopic } from "../types/Atopic";
import type { LocalReaction, Reaction, ReactionType } from "../types/Reaction";
import imageCompression from "browser-image-compression";
import { blobToDataURL } from "../util/blob-to-dataurl";
import * as localforage from "localforage";
import { userId } from "./user-store";

const localReactions = writable<Map<string, LocalReaction>>(new Map());

(async () => {
    localReactions.set(new Map(JSON.parse(await localforage.getItem("reactions"))));
})();

localReactions.subscribe((_localReactions) => {
    // @ts-expect-error
    const serializedData = JSON.stringify([..._localReactions.entries()]);
    localforage.setItem("reactions", serializedData);
});

const apiReactions = writable<Reaction[] | undefined>([]);

export function updateReactionData(_reactions: Reaction[]) {
    localReactions.update((_localReactions) => {
        _reactions.forEach((_reaction) => {
            if (_localReactions.has(_reaction.id)) _localReactions.delete(_reaction.id);
        });
        return _localReactions;
    });
    apiReactions.set(_reactions);
}

function localReactionsSortFunction(a: LocalReaction, b: LocalReaction) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
}

export const reactions: (atopic: Atopic) => Readable<Readonly<(Reaction | LocalReaction)[]>> = (
    atopic
) =>
    derived([apiReactions, localReactions], ([apiReactions, localReactions]) => {
        return [
            ...Array.from(localReactions.values())
                .filter((lr) => lr.atopicId === atopic.id)
                .sort(localReactionsSortFunction),
            ...apiReactions,
        ];
    });

function addLocalReaction(reaction: LocalReaction) {
    localReactions.update((r) => r.set(reaction.id, reaction));
}

export async function uploadImageReaction({
    atopic,
    file,
    idempotencyToken,
}: {
    atopic: Atopic;
    file: File;
    idempotencyToken: string;
}) {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        useWebWorker: true,
    };
    try {
        const compressedFile = await imageCompression(file, options);
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return upload({ atopic, type: "image-reaction", idempotencyToken, file: compressedFile });
    } catch (e) {
        throw "error";
    }
}

export async function uploadTextReaction({
    atopic,
    idempotencyToken,
    text,
}: {
    atopic: Atopic;
    idempotencyToken: string;
    text: string;
}) {
    try {
        return upload({ atopic, type: "text-reaction", idempotencyToken, text });
    } catch (e) {
        throw "error";
    }
}

export async function uploadAudioReaction({
    atopic,
    idempotencyToken,
    file,
}: {
    atopic: Atopic;
    idempotencyToken: string;
    file: Blob;
}) {
    try {
        return upload({ atopic, type: "audio-reaction", idempotencyToken, file });
    } catch (e) {
        throw "error";
    }
}

async function upload({
    atopic,
    type,
    idempotencyToken,
    file,
    text,
}: {
    atopic: Atopic;
    type: ReactionType;
    idempotencyToken: string;
    file?: Blob;
    text?: string;
}) {
    const apiUrl = `/api/de/reactions/${atopic.id}?token=${idempotencyToken}`;

    try {
        // create form data
        const formData = new FormData();
        formData.append("type", type);
        formData.append("userId", userId);
        if (type === "image-reaction") {
            formData.append("image", file);
        } else if (type === "audio-reaction") {
            formData.append("audio", file);
        } else if (type === "text-reaction") {
            formData.append("text", text);
        }
        const request = await fetch(apiUrl, {
            method: "POST",
            body: formData,
        });

        if (request.status === 200) {
            const response: { uuid: string } = await request.json();
            addLocalReaction({
                id: response.uuid,
                type,
                audio:
                    type === "audio-reaction"
                        ? { url: await blobToDataURL(file), mime: file.type }
                        : undefined,
                image:
                    type === "image-reaction"
                        ? {
                              url: await blobToDataURL(file),
                              src1x: undefined,
                              src2x: undefined,
                              src3x: undefined,
                          }
                        : undefined,
                text,
                local: true,
                atopicId: atopic.id,
                date: new Date().toJSON(),
            });
            return response;
        } else {
            throw "ERROR";
        }
    } catch (error) {
        throw "ERROR";
    }
}
