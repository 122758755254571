<script lang="ts">
    import { onMount } from "svelte";
    import CardContainer from "./CardContainer.svelte";
    import Forward from "./Forward.icon.svelte";
    import Pause from "./Pause.icon.svelte";
    import Rewind from "./Rewind.icon.svelte";

    export let url: string;
    export let mime: string;
    export let autoplay = true;
    export let loop = true;

    let progress = 0;
    let paused = true;
    let error = false;
    let currentTime = 0;
    let duration = 1;

    function handlePlayPause(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        paused = !paused;
    }

    function fastForward(e: Event) {
        currentTime += 2;
    }

    function rewind() {
        currentTime -= 2;
    }

    const handleError = (e: Event) => {
        console.error(e);
        error = true;
    };

    $: progress = (currentTime / duration) * 100;
</script>

<CardContainer>
    <div class="progress" style={`--x: ${progress}%`} />
    {#if !error}
        <div class="controls">
            <Rewind on:click={rewind} />
            {#if !paused}
                <span on:click={handlePlayPause}>
                    <Pause />
                </span>
            {:else}
                <span on:click={handlePlayPause}>▶</span>
            {/if}
            <Forward on:click={fastForward} />
        </div>
    {:else}
        <span class="error"> Audio konnte nicht geladen werden. </span>
    {/if}

    <audio
        {autoplay}
        {loop}
        preload="auto"
        bind:paused
        bind:currentTime
        bind:duration
        on:error={handleError}
        on:abort={handleError}
        on:stalled={handleError}
    >
        <source src={url} type={mime} on:error={handleError} />
        Dein Browser unterstützt leider keine Audiodateien
    </audio>
</CardContainer>

<style>
    .progress {
        position: absolute;
        background: rgb(210, 210, 210);
        left: -100%;
        top: 0;
        bottom: 0;
        width: 100%;
        transform: translateX(var(--x, 0));
        transform-origin: left;
        /* transition: transform 200ms linear; */
    }

    .error {
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate3d(-50%, -50%, 0);
        color: red;
        position: absolute;
    }

    .controls {
        font-size: 3rem;
        line-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate3d(-50%, -50%, 0);
        transition: transform 50ms;
        transform-origin: 50% 50%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 0em;
    }

    span {
        width: 1em;
        text-align: center;
        vertical-align: middle;
    }
</style>
