import type { TransitionConfig } from "svelte/types/runtime/transition";
import { springIn, springOut } from "../util/svelte-helpers";

type Position = { x: number; y: number };

type SpringConfig = {
    stiffness: number;
    damping: number;
    precision?: number;
};

type DitchFn = (
    node: Element,
    config: { inOut: boolean; resetStyle?: boolean }
) => TransitionConfig;
function createRandomAngle(span = 5) {
    return (-1 + Math.random() * 2) * span;
}

function createRandomPosition(node: Element) {
    const rect = node.getBoundingClientRect();
    const x = rect.left;
    const y = rect.top;
    const centered = x === 0 && y === 0;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const outOfScreenDistanceX = screenWidth * 1.5;
    // const outOfScreenDistanceY = screenHeight * 1.5;
    const randomAngle = Math.random() * Math.PI * 2;
    // if (centered) {
    const randomX = (Math.random() > 0.5 ? -1 : 1) * outOfScreenDistanceX;
    const randomY = (Math.random() * 2 - 1) * screenHeight * 0.5;
    return { x: randomX, y: randomY };
    // } else {
    //     const slope = x / (y || 1);
    //     const randomX = (x > 0 ? 1 : -1) * outOfScreenDistanceX;
    //     const randomY = randomX * slope;
    //     console.log(randomX, randomY, y);
    //     return { x: randomX, y: randomY };
    // }
}

export const ditch: DitchFn = (node, { inOut, resetStyle }) => {
    const springConfig: SpringConfig = inOut
        ? {
              stiffness: 0.1,
              damping: 0.45,
              precision: 0.01,
          }
        : {
              stiffness: 0.01,
              damping: 0.45,
              precision: 0.01,
          };

    const springFn = inOut ? springIn : springOut;

    const zero = { x: 0, y: 0 };
    const targetPosition = createRandomPosition(node);
    const targetAngle = createRandomAngle(180);

    let { duration: positionSpringDuration, tickToValue: positionSpringTickToValue } = springFn(
        inOut ? targetPosition : zero,
        inOut ? zero : targetPosition,
        springConfig
    );
    let { duration: angleSpringDuration, tickToValue: angleSpringTickToValue } = springFn(
        inOut ? targetAngle : 0,
        inOut ? 0 : targetAngle,
        springConfig
    );

    const style = getComputedStyle(node);
    // const target_opacity = +style.opacity;
    const transform = style.transform === "none" ? "" : style.transform;
    if (resetStyle) {
        // @ts-expect-error
        if (node.style.animation) node.style = null;
    }

    return {
        delay: inOut ? 500 : 0,
        duration: resetStyle ? 1000 : inOut ? 1000 : 2000,
        css: (t, u) => {
            let css = "";
            css += `transform: ${transform} translateX(${
                positionSpringTickToValue(t).x
            }px) translateY(${positionSpringTickToValue(t).y}px) rotateZ(${angleSpringTickToValue(
                t
            )}deg);`;
            css += `z-index: ${inOut ? 2 : 1};`;
            // css += `pointer-events: none;`;
            // css += `opacity: ${Math.min(1 * t * 3, 1)}`;
            return css;
        },
    };
    // return (inOut ? ditchOut : ditchIn).(node, {position, angle, springConfig}): ;
};
