<script lang="ts">
    import { onMount } from "svelte";
    import { replace } from "svelte-spa-router";
    import Error from "../lib/Error.svelte";
    import { atopics, sharedLists } from "../stores/atopic-store";
    import { decode, key } from "../util/share";

    export let params: { wild: string };
    let data: any = undefined;
    const { wild: shareData } = params;
    onMount(() => {
        try {
            data = decode(shareData, $atopics);
            if (data.isList) {
                sharedLists.update((map) => map.set(key(data.list), data.list));
                replace(`/list/${shareData}`);
            } else {
                replace(`/${data.link}`);
            }
        } catch (e) {
            console.error(e);
        }
    });
</script>

{#if !data}
    <Error>Diese Liste ist fehlerhaft</Error>
{/if}
