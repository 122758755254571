<script lang="ts">
    import { onMount } from "svelte";

    export let value = "";
    export let minRows = 1;
    export let maxRows: number;

    let textarea;

    onMount(() => {
        textarea?.focus();
    });

    $: minHeight = `${1 + minRows * 1.3}rem`;
    $: maxHeight = maxRows ? `${1 + maxRows * 1.3}rem` : `auto`;
</script>

<div class="container">
    <pre aria-hidden="true" style="min-height: {minHeight}; max-height: {maxHeight}">{value +
            "\n"}</pre>

    <textarea bind:value bind:this={textarea} />
</div>

<style>
    .container {
        position: relative;
    }

    pre,
    textarea {
        font-family: inherit;
        padding: 0.5em;
        box-sizing: border-box;
        line-height: 1.3rem;
        overflow: hidden;
        border: none;
    }

    textarea {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        resize: none;
        font-size: 1.1rem;
    }

    textarea:focus {
        outline: 0;
        outline-color: transparent;
        outline-style: none;
    }
</style>
