<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { faces } from "../routes/routes";
    import type { Atopic } from "../types/Atopic";

    import Ditchable from "./Ditchable.svelte";
    export let face: string;
    export let atopic: Atopic;

    const dispatch = createEventDispatcher();
</script>

<Ditchable
    handleDitch={() => dispatch("ditch")}
    morphable={false}
    currentFace={face}
    isDitchable={() => true}
    isDraggable={() => true}
>
    <svelte:component this={faces[face] || null} on:click {atopic} />
</Ditchable>
